var exports = {};

function _getPrototypeOf(o) {
  exports = _getPrototypeOf = Object.setPrototypeOf ? Object.getPrototypeOf : function _getPrototypeOf(o) {
    return o.__proto__ || Object.getPrototypeOf(o);
  }, exports.__esModule = true, exports["default"] = exports;
  return _getPrototypeOf(o);
}

exports = _getPrototypeOf, exports.__esModule = true, exports["default"] = exports;
export default exports;
export const __esModule = exports.__esModule;